#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
}

#header .header1 .container .content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    color: white;
    height: 100%;
}

#header .header1 .container .content .title {
    text-align: left;
    text-transform: initial;
    color: white;
    font-size: 80px;
    font-weight: 100;
    line-height: 5rem;
}

#header .header1 .container .content .title span {
    font-weight: bold;
    font-size: 150px;
    font-family: "Rozha One";
}

#header .header1 .container .content .text {
    font-size: 28px;
}

#header .header1 .container .content #ctaWppOther {
    margin-right: auto;
    width: 70%;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header2 .container .title {
    color: black;
}

#header .header2 .container .text {
    text-align: center;
}

#header .header2 .container #ctaWppOther {
    margin: 0 auto;
    width: 30%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 70%;
        background-size: cover;
        height: 80vh;
    }

    #header .header1 .container .content {
        width: 100%;
        justify-content: flex-start;
    }

    #header .header1 .container .content .title {
        text-align: center;
        font-size: 80px;
    }

    #header .header1 .container .content .title span {
        font-size: 100px;
    }

    #header .header1 .container .content .text {
        text-align: center;
        margin-top: auto;
    }

    #header .header1 .container .content #ctaWppOther {
        margin: 0 auto;
    }

    #header .header2 .container #ctaWppOther {
        width: 70%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 77vh;
    }

    #header .header1 .container .content .title {
        text-align: center;
        font-size: 60px;
        margin-top: 1rem;
    }

    #header .header1 .container .content .title span {
        font-size: 90px;
    }

    #header .header1 .container .content #ctaWppOther {
        width: 80%;
    }

    #header .header2 .container #ctaWppOther {
        width: 80%;
    }
}