#ctaWppOther {
    background: linear-gradient(to left, #F21457, #F17C9F);
    font-weight: 700;
    padding: .7rem 1.5rem;
    border-radius: 10rem;
}

#ctaWppOther a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
}

#ctaWppOther a figure {
    width: 9%;
}

#ctaWppOther a figure img {
    width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWppOther a figure {
        width: 10%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}