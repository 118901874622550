#about .about1 {
  background-color: #E5F4FF;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}

#about .about1 .container .text {
  text-align: center;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
}

#about .about1 .container .gallery .item {
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

#about .about1 .container .gallery .item .bottom {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  padding: 1rem;
}

#about .about1 .container .gallery .item .bottom .item_title {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
}


#about .about1 .container .gallery .item .bottom .text {
  text-align: center;
  font-weight: 400;
}

#about .about1 .container .gallery .item:last-child .bottom .text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .gallery .item:last-child .bottom .text span {
  font-weight: 300;
}

#about .about1 .container .gallery .item .bottom #ctaWppOther {
  margin: 0 auto;
  width: 90%;
}

#about .about1 .container .gallery .item:nth-child(2) .bottom #ctaWppOther {
  margin-top: 1rem;
}

#about .about2 {
  background-color: #EFF8FF;
}

#about .about2 .container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

#about .about2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .left .top {
  display: flex;
  flex-direction: column;
}

#about .about2 .container .left .top .title {
  text-align: left;
}

#about .about2 .container .left .top .mobile {
  display: none;
}

#about .about2 .container .left .top .title2 {
  font-weight: bold;
  text-transform: uppercase;
}

#about .about2 .container .left ul {
  display: flex;
  flex-direction: column;
  gap: .1rem;
}

#about .about2 .container .left ul li {
  display: flex;
  gap: .4rem;
  align-items: center;
}

#about .about2 .container .left ul li figure {
  max-width: 3%;
}

#about .about2 .container .left .text {
  display: flex;
  flex-direction: column;
}

#about .about2 .container .left .text span {
  color: #F21457;
  font-weight: bold;
}

#about .about2 .container .left #ctaWppOther {
  margin-right: auto;
}

#about .about2 .container .right {
  width: 100%;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

#about .about3 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
  padding: 0 10rem;
}

#about .about3 .container .gallery figure:nth-child(1) img {
  width: 80%;
}

#about .about3 .container .gallery figure:nth-child(2) img {
  width: 54%;
}

#about .about3 .container .gallery figure:nth-child(3) img {
  width: 93%;
}

#about .about3 .container h3 {
  color: black;
  font-size: 30px;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
  width: 30%;
  padding: .7rem 0;
}

#about .about3 .container #ctaWpp a figure {
  width: 9%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  #about .about1 .container .gallery .item .bottom #ctaWppOther {
    width: 70%;
  }

  #about .about2 .container .left .top .title {
    text-align: center;
  }

  #about .about2 .container .left .top .title2 {
    text-align: center;
  }

  #about .about2 .container .left .top .mobile {
    display: block;
    margin-top: 1rem;
  }

  #about .about2 .container .left .top .mobile img {
    width: 80%;
  }

  #about .about2 .container .left .text {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  #about .about2 .container .left .text span {
    color: #F21457;
  }

  #about .about2 .container .left #ctaWppOther {
    margin: 0 auto;
    width: 70%;
  }

  #about .about2 .container .right {
    display: none;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    padding: 0;
  }

  #about .about3 .container .gallery figure:nth-child(1) img {
    width: 60%;
  }

  #about .about3 .container .gallery figure:nth-child(2) img {
    width: 40%;
  }

  #about .about3 .container .gallery figure:nth-child(3) img {
    width: 69%;
  }

  #about .about3 .container #ctaWpp {
    width: 70%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .gallery .item .bottom #ctaWppOther {
    width: 90%;
  }

  #about .about2 .container .left #ctaWppOther {
    width: 80%;
  }

  #about .about3 .container #ctaWpp {
    width: 80%;

  }

  #about .about3 .container #ctaWpp a p {
    font-size: 1rem;
  }
}