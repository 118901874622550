#footer .footer1 {
    background-color: #000000;
}

#footer .footer1 .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
}

#footer .footer1 .container figure img {
    width: 25%;
}

#footer .footer1 .container .text {
    color: white;
    text-align: center;
}

#footer .footer2 .container {
    text-align: center;
    padding: 1rem 0;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 35%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container figure img {
        width: 40%;
    }
}